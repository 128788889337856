import React, {useEffect, useState} from 'react'
import {Create, SelectInput, SimpleForm, TextInput, useNotify, Loading} from 'react-admin'
import {required} from "ra-core";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Button from "@material-ui/core/Button";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import axios from "axios";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from 'react-router-dom';

const EmailCampaignsCreate = (props) => {
  const notify = useNotify();
  const history = useHistory();

  const {data} = {
    description: "<p/>"
  }

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [subjectValue, setSubjectState] = useState(null);
  const [productValue, setProductState] = useState(null);
  const [loaderValue, setLoaderState] = useState(false);


  useEffect(() => {
    const html = "<p/><p/><p/>";
    const contentBlock = htmlToDraft(html);
    if (contentBlock && html !== "") {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState)
    }

  }, [data]) //run once on initial render

  useEffect(() => {
    if(editorState) {
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    } else {
      draftToHtml(data.description)
    }
  }) //Run every state change

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)
  }

  const onSubjectStateChange = event => {
    setSubjectState(event.target.value);
  };

  const onProductStateChange = event => {
    setProductState(event.target.value);
  };

  const loaderStateChange = value => {
    setLoaderState(value);
  };

  const create = () => {
    loaderStateChange(true)

    const diff = {
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())) || "<p/>",
      product_id: productValue,
      subject: subjectValue
    };

    if (!diff.subject) {
      notify(`Subject is required`, 'warning', {autoHideDuration: 3000});
    }

    if (!diff.product_id) {
      notify(`Product is required`, 'warning', {autoHideDuration: 3000});
    }

    if (diff.description === "<p/>" || diff.description === "") {
      notify(`Description is required`, 'warning', {autoHideDuration: 3000});
    }

    diff.description = diff.description.replace(/undefined/g, 'image-machine-tech');

    diff.description = diff.description.replace(/<img src/g, '<a href="#" class="url-for-img" ' +
      'style="cursor:default"><img src');
    diff.description = diff.description.replace(/\/>/g, '/></a>');

    const config = {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    }

    if (diff.subject && diff.product_id) {
      axios.post(
        `${process.env.REACT_APP_SERVER}/api/admin/email-campaigns`, diff, config)
        .then((data) => {
          loaderStateChange(false)
          history.push("/email-campaigns");
          notify('Email campaign created', {autoHideDuration: 3000});
        }).catch(err => {
        loaderStateChange(false)
        notify(`updated error: ${err.message}`, 'warning', {autoHideDuration: 3000});
      })
    }
  }

  const CustomToolbar = props => (
    <Toolbar {...props}>
      <Button color="primary" onClick={() => create()} redirect={"show"}>Save</Button>
    </Toolbar>
  );

  if (loaderValue) return <Loading loadingPrimary="Loading..." loadingSecondary=""/>

  return (
    <Create title='Create new Email campaign' {...props}>
      <SimpleForm toolbar={<CustomToolbar />} redirect={"show"}>
        <SelectInput source="product_id" choices={[
          { id: 1, name: 'Air Pair' },
          { id: 2, name: 'Glock' },
        ]} validate={required()}
                     onChange={onProductStateChange}
                     value={productValue}
        />
        <TextInput source='subject' validate={required()}
                   onChange={onSubjectStateChange}
                   value={subjectValue}
        />
        <Editor
          editorState={editorState}
          hasHtmlEditorOption={true}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />

      </SimpleForm>
    </Create>
  )
}

export default EmailCampaignsCreate
