import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';

import orders from '../orders';
import emailCampaigns from '../emailCampaigns';
import customerSubscriptions from '../customerSubscriptions';
import transactions from '../transactions';

const Menu = ({ onMenuClick, dense, logout }) => {
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  useSelector(state => state.theme); // force rerender on theme change

  return (
    <div>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
        <MenuItemLink
          to={`/customers`}
          primaryText={"Subscriptions"}
          leftIcon={<customerSubscriptions.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/orders`}
          primaryText={"Orders"}
          leftIcon={<orders.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/email-campaigns`}
          primaryText={"Email campaigns"}
          leftIcon={<emailCampaigns.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/transactions`}
          primaryText={"Payments"}
          leftIcon={<transactions.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
    </div>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
};

export default Menu;
