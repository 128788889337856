import * as React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import orders from "./orders";
import emailCampaigns from "./emailCampaigns";
import customers from "./customerSubscriptions";
import transactions from "./transactions";
import Dashboard from './Dashboard';
import { Login, LayoutElem } from "./layout";
import authProvider from './authProvider';
import './App.css';
import customDataProvider from './dataProvider';


const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', localStorage.getItem('token'));
  return fetchUtils.fetchJson(url, options);
}

const App = () => (
  <Admin
    dataProvider={customDataProvider(
      `${process.env.REACT_APP_SERVER}/api/admin`, httpClient
    )}
    authProvider={authProvider}
    dashboard={Dashboard}
    loginPage={Login}
    layout={LayoutElem}
  >
    <Resource name="orders" {...orders} />
    <Resource name="email-campaigns" {...emailCampaigns} />
    <Resource name="customers" {...customers} />
    <Resource name="transactions" {...transactions} />
  </Admin>
);

export default App;
