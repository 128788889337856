import * as React from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles({
  cards: {
    margin: '20px',
  },
});

const Dashboard = () => {
  const classes = useStyles();
  return (
    <div>
      <Card className={classes.cards}>
        <CardHeader title="Welcome to the Machine Trigger Dashboard"/>
        <CardContent>
        </CardContent>
      </Card>
      <Grid container>
        <Grid item xs={6}>
          <Link underline='none' component={RouterLink} to={'/orders'}>
            <Card className={classes.cards}>
              <CardHeader title="Orders"/>
              <CardContent>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link underline='none' component={RouterLink} to={'/email-campaigns'}>
            <Card className={classes.cards}>
              <CardHeader title="Email campaigns"/>
              <CardContent>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link underline='none' component={RouterLink} to={'/customers'}>
            <Card className={classes.cards}>
              <CardHeader title="Subscriptions"/>
              <CardContent>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link underline='none' component={RouterLink} to={'/transactions'}>
            <Card className={classes.cards}>
              <CardHeader title="Payments"/>
              <CardContent>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
