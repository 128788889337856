import OrderIcon from '@material-ui/icons/AttachMoney';

import OrdersList from './OrdersList';
import OrdersEdit from './OrdersEdit';

const Orders = {
  list: OrdersList,
  edit: OrdersEdit,
  icon: OrderIcon,
};

export default Orders
