import React from 'react';
import {
  Datagrid,
  DateField,
  // EditButton,
  List,
  TextField,
  DeleteButton,
  Filter,
  TextInput,
  BooleanInput, FunctionField
} from 'react-admin';

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput source='email' alwaysOn />
    <BooleanInput label="Air Pair" source="air_pair" alwaysOn/>
    <BooleanInput label="Glock" source="glock" alwaysOn />
  </Filter>
);

const CustomerSubscriptionsList = (props) => {
  return (
    <List {...props} filters={<PostFilter />} filterDefaultValues={{ confirmed: true }}   sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid>
        <TextField source='email' />
        <FunctionField label="Air Pair" render={record => record.air_pair === true ? "Subscribed" : "Unsubscribed"}
                       source='product_id'/>
        <FunctionField label="Glock" render={record => record.glock === true ? "Subscribed" : "Unsubscribed"}
                       source='product_id'/>
        <DateField source='created_at' />
        {/*<EditButton basePath='customers' />*/}
        <DeleteButton basePath='customers'  label={"Delete"}/>
      </Datagrid>
    </List>
  )
}

export default CustomerSubscriptionsList;
