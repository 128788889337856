import EmailIcon from '@material-ui/icons/Email';

import EmailCampaignsList from './EmailCampaignsList';
import EmailCampaignsEdit from './EmailCampaignsEdit';
import EmailCampaignsCreate from "./EmailCampaignsCreate";
import EmailCampaignsShow from "./EmailCampaignsShow";

const emailCampaigns = {
  list: EmailCampaignsList,
  edit: EmailCampaignsEdit,
  create: EmailCampaignsCreate,
  show: EmailCampaignsShow,
  icon: EmailIcon,
};

export default emailCampaigns
