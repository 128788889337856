import React from 'react'
import {
  DeleteButton,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  useNotify, useQuery,
} from 'react-admin'
import axios from "axios";
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import header from '../assets/header.png'
import icon_facebook from '../assets/facebook.png'
import icon_instagram from '../assets/instagram.png'
import icon_twitter from '../assets/twitter.png'

const useStyles = makeStyles(theme => ({
  description: {
    margin: '0',
    padding: '0 0 9px 0',
    textAlign: 'center',
    width: '560px',
    fontFamily: 'Raleway, sans-serif',
    opacity: '0.98',
    fontSize: '18px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: '24px',
    color: '#2a2f2a'
  },
  socialLabel: {
    margin: '0',
    padding: '0 0 9px 0',
    textAlign: 'center',
    width: '560px',
    fontFamily: 'Raleway, sans-serif',
    opacity: '0.98',
    fontSize: '12px',
    fontWeight: '700',
    fontStyle: 'normal',
    letterSpacing: '1.9px',
    lineHeight: 'normal',
    textTransform: 'uppercase'
  },
  email: {
    color: '#40a044',
    fontFamily: 'Raleway, sans-serif',
    fontSize: '18px',
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: '25px',
    textAlign: 'center'
  },
  socialBg: {
    width: '620px',
    height: '372px',
    backgroundColor: '#f4f4f4'
  },
  imageContainer: {
    width: '36px',
    height: '36px'
  },
  a: {
    'margin':0, padding: '0 0 9px 0', textAlign: 'center', textDecoration: 'none'
  },
  tableFollowIcons: {
    marginLeft: '10px'
  },
  unsubscribeText: {
    margin: '0',
    padding: '0 0 9px 0',
    textAlign: 'center',
    width: '560px',
    fontFamily: 'Raleway, sans-serif',
    opacity: '0.98',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: '24px',
    color: '#2a2f2a',
    fontSize: '14px',
    fontWeight: '500'
  },
  unsubscribeLink: {
    color: '#40a044',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    textDecoration: 'underline'
  }
}));

const EmailCampaignsShow = (props) => {
  const { data } = useQuery({
    type: 'getOne',
    resource: 'email-campaigns',
    payload: {id: props.id}
  });
  const classes = useStyles();
  const notify = useNotify();
  const sendEmail = (id) => {
    const config = {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    }
    axios.get(
        `${process.env.REACT_APP_SERVER}/api/admin/email-campaigns/${id}/send`, config)
      .then((data) => {
        notify('Email sent', { autoHideDuration: 3000 });
      }).catch(err => {
      notify(`Email sending error: ${err.message}`, 'warning', { autoHideDuration: 3000 });
    })
  }

  return (
    <Show title='Show email campaign' {...props}>
      <SimpleShowLayout>
        <Button color="primary" onClick={() => (props.id ? sendEmail(props.id) : null)}>Send email</Button>
        <FunctionField label="Product" render={record => record.product_id === 2 ? "Glock" : "Air Pair"} />
        <TextField source='subject' />

        {/*Email Template View*/}
        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
          <tr>
            <td align="center">
              <img src={header} width="560" height="140" alt="header" />
            </td>
          </tr>
        </table>

        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
          <tr>
            <td align="center">
              <div className={classes.description}>
                <div dangerouslySetInnerHTML={{ __html: data && data.description ? data.description : "" }} />
              </div>
            </td>
          </tr>
        </table>

        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
          <tr>
            <td align="center">
              <div className={classes.socialBg}> <br/>
                <p className={classes.socialLabel}>FOLLOW</p> <br/>

                <table width="150" border="0" cellSpacing="0" cellPadding="0" className={classes.tableFollowIcons}>
                  <tr>
                    <td>
                      <a href="https://twitter.com/teammachinetech" target="_blank" rel="noreferrer">
                        <div className={classes.imageContainer}>
                          <img  src={icon_twitter} alt=""/>
                        </div>
                      </a>
                    </td>
                    <td>
                      <a href="https://www.facebook.com/teammachinetech" target="_blank" rel="noreferrer">
                        <div className={classes.imageContainer}>
                          <img src={icon_facebook} alt="" />
                        </div>
                      </a>
                    </td>
                    <td>
                      <a href="https://www.instagram.com/teammachinetech/" target="_blank" rel="noreferrer">
                        <div className={classes.imageContainer} >
                          <img src={icon_instagram} alt="" />
                        </div>
                      </a>
                    </td>
                  </tr>
                </table>
                <br/>
                <p className={classes.socialLabel}>ORDERS</p>
                <a href="mailto:orders@machine.tech" className={classes.a}>
                  <p className={classes.email}>orders@machine.tech</p>
                </a>
                <p className={classes.socialLabel}>SUPPORT</p>
                <a href="mailto:hello@machine.tech" className={classes.a}>
                  <p className={classes.email}>hello@machine.tech</p>
                </a>

                <p className={classes.unsubscribeText}>
                  No longer interested? <span className={classes.unsubscribeLink}>
                  Unsubscribe
                </span>
                </p>
              </div>
            </td>
          </tr>
        </table>
        {/*End of Email Template View*/}
        <DeleteButton basePath='email-campaigns' label={"Delete"} style={{textAlign: 'right'}}/>
      </SimpleShowLayout>
    </Show>
  )
}

export default EmailCampaignsShow;
