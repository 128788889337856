import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';

const dataProvider =  (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;

    const filterString = paramsFilters => {
      let queryString = ""
      if (paramsFilters.length === 0) {
        return queryString;
      } else {
        Object.entries(paramsFilters).forEach((pFilter, index) => {
          if ((pFilter[0]==="glock" && pFilter[1]) || (pFilter[0]==="air_pair" && pFilter[1]) ||
            (pFilter[0] !=="air_pair" && pFilter[0] !=="glock")) {
            queryString = queryString + pFilter[0] + ":" + pFilter[1] + ",";
          }
        })
        queryString = queryString.substring(0, queryString.length - 1);
        return queryString
      }
    }

    const query = {
      // ...fetchUtils.flattenObject(params.filter),
      sortby: params.sort.field,
      order: params.sort.order.toLowerCase(),
      offset: (page - 1) * perPage,
      limit: perPage,
      query:  filterString(params.filter)
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {

      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses ' +
          'for lists of resources to contain this header with the total number of results to build the pagination. ' +
          'If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        );
      }
      return {
        data: json !== null ? json : [],
        total: parseInt(
          headers.get('x-total-count').split('/').pop(),
          10
        ),
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      sortby: params.sort.field,
      order: params.sort.order.toLowerCase(),
      offset: (page - 1) * perPage,
      limit: perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses ' +
          'for lists of resources to contain this header with the total number of results to build the pagination. ' +
          'If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        );
      }
      return {
        data: json !== null ? json : [],
        total: parseInt(
          headers.get('x-total-count').split('/').pop(),
          10
        ),
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
});

export default dataProvider
