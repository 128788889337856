import React from 'react';
import {
  Datagrid,
  DateField,
  // EditButton,
  List,
  TextField,
  FunctionField, Filter, TextInput,
  // DeleteButton
} from 'react-admin';

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput source='email' alwaysOn />
  </Filter>
);

const TransactionsList = (props) => {
  return (
    <List {...props} filters={<PostFilter />} bulkActionButtons={false}  sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid>
        <TextField source='email' />
        <TextField source='name' />
        <FunctionField label="Product" render={record => record.product_id === 2 ? "Glock" : "Air Pair"}
                       source='product_id'/>
        <TextField source='payment_method' />
        <TextField source='stripe_order_id' label='Stripe order id'/>
        <DateField source='created_at' />
        {/*<EditButton basePath='transactions' />*/}
        {/*<DeleteButton basePath='transactions' />*/}
      </Datagrid>
    </List>
  )
}

export default TransactionsList;
