import React from 'react'
import {Edit, SimpleForm, TextInput, SelectInput} from 'react-admin'

const OrdersEdit = (props) => {
  return (
    <Edit title='Edit order' {...props}>
      <SimpleForm>
        <TextInput disabled source='stripe_order_id'  label='Stripe order id' />
        <SelectInput source="status" choices={[
          { id: 'paid', name: 'Paid' },
          { id: 'pending', name: 'Pending' },
          { id: 'shipped', name: 'Shipped' },
          { id: 'cancelled', name: 'Cancelled' },
          { id: 'returned', name: 'Returned' },
        ]} />
      </SimpleForm>
    </Edit>
  )
}

export default OrdersEdit;
