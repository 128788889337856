import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  TextField,
  FunctionField,
  DeleteButton,
  ShowButton, Filter, TextInput, useNotify
} from 'react-admin';
import Button from '@material-ui/core/Button';
import axios from "axios";

const PostFilter = (props) => (
  <Filter {...props}>
      <TextInput source='subject' alwaysOn />
  </Filter>
);

const CustomDescriptionField = ({ record }) => {
  if (record && record.description) {
    let str = record.description;
    return record ? (
      <span>{str.length > 30 ? str.slice(0, 30) + "..." : str}</span>
    ) : null;
  }
  return null
};

const EmailCampaignsList = (props) => {
  const notify = useNotify();

  // create custom component
  const ButtonField = ({source, record}) => (
    <Button color="primary" onClick={()=>{
      const config = {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }
      axios
        .get(`${process.env.REACT_APP_SERVER}/api/admin/email-campaigns/${record.id}/send`, config)
        .then((res)=> {
          notify('Email sent', { autoHideDuration: 3000 });
        }).catch(err => {
        notify(`Email sending error: ${err.message}`, 'warning', { autoHideDuration: 3000 });
      })
    }}>Send</Button>
  );

  return (
    <List title='Email campaigns' {...props} filters={<PostFilter />}  sort={{ field: 'created_at', order: 'DESC' }}>
      <Datagrid>
        <TextField source='subject' />
        <CustomDescriptionField source="description"/>
        <FunctionField label="Product" render={record => record.product_id === 2 ? "Glock" : "Air Pair"}
                       source='product_id' />
        <TextField source='status' />
        <DateField source='created_at' />
        <DateField source='updated_at' defaultValue='' />
        <DateField source='sent_at' defaultValue='' />
        <ShowButton basePath='email-campaigns' label={"Preview"}/>
        <ButtonField source="id" label="Send"  sortable={false}/>
        <EditButton basePath='email-campaigns' label={"Edit"}/>
        <DeleteButton basePath='email-campaigns' label={"Delete"}/>
      </Datagrid>
    </List>
  )
}

export default EmailCampaignsList;
