import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  TextField,
  FunctionField, Filter, TextInput,
} from 'react-admin';

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput source='id' label='Order id' alwaysOn />
    <TextInput source='customer_email' alwaysOn />
    <TextInput source='stripe_order_id' label='Stripe order id' alwaysOn />
  </Filter>
);

const OrdersList = (props) => {
  return (
    <List {...props} filters={<PostFilter />} sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid>
        <TextField source='id' label='Order id'/>
        <TextField source='stripe_order_id' label='Stripe order id'/>
        <TextField source='customer_email' />
        <TextField source='name' />
        <TextField source='address' />
        <TextField source='state' />
        <TextField source='city' />
        <TextField source='zip_code' />
        <FunctionField label="Product" render={record => record.product_id === 2 ? "Glock" : "Air Pair"}
                       source='product_id'/>
        <TextField source='pistol_type' />
        <TextField source='pistol_amount' />
        <TextField source='t_shirt_size' />
        <TextField source='status' />
        <DateField source='created_at' />
        <EditButton basePath='orders'  label={"Edit"}/>
        {/*<DeleteButton basePath='orders' />*/}
      </Datagrid>
    </List>
  )
}
export default OrdersList;

