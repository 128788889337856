import SubscriptionsIcon from '@material-ui/icons/Subscriptions';

import CustomerSubscriptionsList from './CustomerSubscriptionsList';

const CustomerSubscriptions =  {
  list: CustomerSubscriptionsList,
  icon: SubscriptionsIcon,
};

export default CustomerSubscriptions
