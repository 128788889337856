import React, {useEffect, useState} from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  useGetOne,
  useNotify,
  Loading
} from 'react-admin'
import {required} from "ra-core";
import Button from '@material-ui/core/Button'

import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import axios from "axios";

const EmailCampaignsEdit = (props) => {
  const { data } = useGetOne('email-campaigns', props.id)
  const [editorState, setEditorState] = useState( () => EditorState.createEmpty(),);

  const [subjectValue, setSubjectState] = useState(null);
  const [statusValue, setStatusState] = useState(null);
  const [loaderValue, setLoaderState] = useState(false);

  const onSubjectStateChange = event => {
    setSubjectState(event.target.value);
  };

  const onStatusStateChange = event => {
    setStatusState(event.target.value);
  };

  const loaderStateChange = value => {
    setLoaderState(value);
  };

  const notify = useNotify();

  useEffect(() => {
    const html = data && data.description ? data.description : "<p/>";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState)
    }
  }, [data]) //run once on initial render

  useEffect(() => {
    if(editorState) {
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }
  }) //Run every state change

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)
  }

  const updateEmail = (event, id) => {
    loaderStateChange(true)

    const diff = {
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())) || "<p/>",
      product_id: 2,
      subject: subjectValue || data.subject,
      status: statusValue || data.status
    };

    if (!diff.subject) {
      notify(`Subject is required`, 'warning', {autoHideDuration: 3000});
    }

    if (diff.description === "<p/>" || diff.description === "") {
      notify(`Description is required`, 'warning', {autoHideDuration: 3000});
    }

    diff.description = diff.description.replace(/undefined/g, 'image-machine-tech');

    diff.description = diff.description.replace(/<img src/g, '<a href="#" class="url-for-img" ' +
      'style="cursor:default"><img src');
    diff.description = diff.description.replace(/\/>/g, '/></a>');

    const config = {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    }

    axios.put(
      `${process.env.REACT_APP_SERVER}/api/admin/email-campaigns/${id}`, diff, config)
      .then((data) => {
        loaderStateChange(false)
       notify('Email campaign updated', {autoHideDuration: 3000});
      }).catch(err => {
      loaderStateChange(false)
      notify(`Updated error: ${err.message}`, 'warning', {autoHideDuration: 3000});
    })
  }

  if (loaderValue) return <Loading loadingPrimary="Loading..." loadingSecondary=""/>

  return (
    <Edit title='Edit email campaign' {...props}>
      <SimpleForm toolbar={false}>
        <TextInput source='subject' validate={required()}
                   onChange={onSubjectStateChange}
                   value={subjectValue}
         />
        <SelectInput source="status" choices={[
          {id: 'draft', name: 'Draft'},
          {id: 'created', name: 'Created'},
          {id: 'sent', name: 'Sent'}
        ]}
           onChange={onStatusStateChange}
           value={statusValue}/>

        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
        <Button color="primary" style={{width:'40px'}}
                onClick={(event) => updateEmail(event, props.id)} redirect={"show"}>Save</Button>

      </SimpleForm>
    </Edit>
  )
}

export default EmailCampaignsEdit;
